import React from "react"
import Wrapper from "../components/Wrapper"

const NotFoundPage = () => (
  <main id="not-found-page">
    <Wrapper size="l" className="py-32 my-32 mx-auto">
      <a href="https://lochenveien.bonum.no">
        &larr; Gå tilbake
      </a>

      <Wrapper size="s">
        <h1 className="mt-12 mb-20 text-6xl">
          Kunne ikke finne siden
        </h1>

        <div>
          <p>Det ser dessverre ikke ut som vi fant siden du prøvde å nå. Hvis du mener dette er en feil på vårt system, ber vi deg vennligst ta kontakt på <a href="mailto:kontakt@bonum.no" className="underline">kontakt@bonum.no</a>, slik at vi kan søke etter feilen og rette den.</p>
        </div>
      </Wrapper>
    </Wrapper>
  </main>
)

export default NotFoundPage
