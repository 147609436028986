import React from "react"
import styled from "styled-components"

interface WrapperProps {
  size?: string
  className?: string
  children?: React.ReactNode
  style?: object
  dangerouslySetInnerHTML?: {
    __html: string
  }
}

const Wrapper: React.FC<WrapperProps> = ({
  size,
  className,
  children,
  style
}) => {
  let width = ""

  switch(size) {
    case "xxl":
      width = "1920px"
      break
    case "xl":
      width = "1200px"
      break
    case "l":
      width = "1024px"
      break
    case "m":
      width = "820px"
      break
    case "s":
      width = "650px"
      break
    case "xs":
      width = "350px"
      break
    default:
      width = "1200px"
  }

  const Elem = styled.div`max-width: ${width}`

  return (
    <Elem className={className || ""} style={style}>
      {children}
    </Elem>
  )
}

export default Wrapper
